<template>
    <div>
        <div class="p_title fff">
            <pageTitle :title="$t('Set host')">
                <template slot="right">
                <!-- 发红包  -->
                    <el-button size="small" type="text" @click="openSetGuest" style="padding-right:10px">{{$t('Set host')}}</el-button>
                </template>
            </pageTitle>
            
        </div>
        <div class="line fff"></div>
        <div class="" style="margin-bottom:15px">
            <el-table 
                :data="data_host"
                style="width: 100%;" 
                :tableLoading="loading"
            >
                <!-- 主持人 -->
                <el-table-column  :label="$t('Moderator')">
                    <div slot-scope="scope" class="tit">
                        <img :src="scope.row.avatar?scope.row.avatar:DEFAULT_AVATAR" >
                        <div>{{ !scope.row.nickname||scope.row.nickname==""?$t('Moderator'):scope.row.nickname}}</div>
                    </div>
                </el-table-column>
                <!-- 频道号 -->
                <el-table-column :label="$t('ChannelName')" prop='account' ></el-table-column>
                <!-- 频道密码 -->
                <el-table-column :label="$t('Channelpassword')" prop='passwd'></el-table-column>
                <!-- 操作 -->
                <el-table-column :label="$t('handle')" width="200">
                    <template slot-scope="scope">
                        <el-button @click="handleCopy(scope.row,'zhuchiren')" type="text" size="small">{{$t('meetingCopy')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="lineBlock"></div>
        <div class="p_title fff">
            <pageTitle :title="$t('Setguest')"></pageTitle>
        </div>
        <div class="line fff"></div>
        <div class="" style="margin-bottom:15px">
            <el-table 
                :data="data_jb"
                style="width: 100%;" 
                :tableLoading="loading"
            >
                <el-table-column  :label="$t('Guest')">
                    <div slot-scope="scope" class="tit">
                        <img :src="scope.row.avatar?scope.row.avatar:DEFAULT_AVATAR" >
                        <div>{{ !scope.row.nickname||scope.row.nickname==""?$t('Guest'):scope.row.nickname}}</div>
                    </div>
                </el-table-column>
                <!-- 嘉宾号 -->
                <el-table-column :label="$t('Guest_number')" prop='account' ></el-table-column>
                <!-- 嘉宾密码 -->
                <el-table-column :label="$t('Guestpassword')" prop='passwd'></el-table-column>
                <!-- 操作 -->
                <el-table-column :label="$t('Edit')" width="200">
                    <template slot-scope="scope">
                        <!-- 设置 -->
                        <el-button @click="setJiabing(scope.row)" type="text" size="small">{{$t('Setup')}}</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <!-- 复制 -->
                        <el-button type="text" size="small"  @click="handleCopy(scope.row,'jiabin')">{{$t('meetingCopy')}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- 设置主持人 -->
        <el-dialog
            :title="$t('Set host')"
            :visible.sync="dialog_setGuest"
            width="30%">
            <el-form :model="ruleForm_host" :rules="rules_host" ref="ruleForm_host" label-width="100px">
                <el-form-item :label="$t('Moderator')+'：'" prop="guest_name">
                    <el-input v-model="ruleForm_host.guest_name" size="small"  :placeholder="$t('Pleasenickname')" ></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button  size="small" @click="dialog_setGuest = false">{{$t('cancel')}}</el-button>
                <el-button type="primary"  size="small" @click="sure_setGuest('ruleForm_host')">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
        <!-- 添加编辑嘉宾 -->
        <el-dialog
            :title="$t('Guestsettings')"
            :visible.sync="dialog_jiabin"
            width="40%"
        >
            <el-form :model="jiabinForm" :rules="rules_jb" ref="ruleForm_jb" :label-position="'left'" :label-width="LOCALE=='zh'?'120px':'200px'" >
                <el-form-item :label="$t('Guestheadportrait')+'：'" >
                    <div class="avaBox">
                        <div>
                            <img :src="jiabinForm.avatar" alt="" style="width: 50px;height:50px;border-radius:50%;">
                            <fileUpload
                                @uploadCompleteOSS="_uploadCompleteOSS"
                                :accept="fileUploadOptions.accept"
                                :needCropImg="true"
                                aspectRatio='140/140'
                                :limitSize='1'
                                :autoUpload="fileUploadOptions.autoUpload"
                            >
                                <el-button type="text" size="small">{{$t('changeheadportrait')}}</el-button>
                            </fileUpload>
                        </div>
                        <span>{{$t('size140_140')}}</span>
                    </div>
                </el-form-item>
                <el-form-item :label="$t('GuestNickname')+'：'" prop="nickname">
                    <el-input v-model="jiabinForm.nickname" autocomplete="off"   size="small" maxlength="8" show-word-limit></el-input>
                </el-form-item>
                <el-form-item :label="$t('Guestpassword')+'：'" prop="password">
                    <el-input v-model="jiabinForm.password" autocomplete="off"   size="small" maxlength="16" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button  size="small" @click="dialog_jiabin = false">{{$t('cancel')}}</el-button>
                <el-button  size="small" type="primary" @click="sure_jiabin('ruleForm_jb')">{{$t('Confirm')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";
import fileUpload from "@/baseComponents/fileUpload";
import { validatePassWord } from "~/baseUtils/validate";
export default {
    name:"conference-attendance",
    components:{
        pageTitle,
        fileUpload,
    },
    data(){
        var checkPass = (rule, value, callback) => {
            if (!validatePassWord(value)) {
                return callback(new Error(this.$t('wrongPasswordFormat')));
            }else{
                callback();
            }
            
        };
        return{
            title:"",
            loading:false,
            total:20,
            currentPage:1,
            dialog_jiabin:false,
            ruleForm_host:{
                guest_name:"",
            },
            jiabinForm:{
                avatar:"",
                nickname:"",
                password:"",
            },
            fileUploadOptions: {
                accept: ".jpg,.jpeg,.bmp,.png",
                autoUpload:true
            },
            dialog_setGuest:false,
            data_host:[],
            data_jb:[],
            data_item:{},
            rules_host:{
                guest_name:[
                    { required: true, message: this.$t('hostsnickname'), trigger: 'blur' }
                ]
            },
            rules_jb:{
                nickname:[
                    { required: true, message: this.$t('hostsnickname'), trigger: 'blur' },
                    { min: 1, max: 8, message: this.$t('length_1_8'), trigger: 'blur' }
                ],
                password:[
                    { required: true, message: this.$t('guest password'), trigger: 'blur' },
                    {  validator: checkPass, trigger: 'blur' }
                ]
            }
        }
    },
    mounted(){
        this.getList()
        console.log(this.$store);
    },
    methods:{
        async getList(){
            this.data_host=[]
            this.data_jb=[]
            this.loading=true
            let res = await this.$store.dispatch("baseConsole/getLIveList",{live_id:this.$route.query.id});
            for(var item of res.guest_list_data){
                if(item.type==1){
                    this.data_host.push(item)
                }
                if(item.type==2){
                    this.data_jb.push(item)
                }
            }
            this.loading=false
        },
        //设置主持人--打开
        openSetGuest(){
            this.ruleForm_host.guest_name=this.data_host[0].nickname
            this.dialog_setGuest=true
        },
        // 设置主持人--提交
        sure_setGuest(formName){
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let params={
                        live_id:this.$route.query.id,
                        start_time:this.$route.query.start_time,
                        channel_id:this.$route.query.channel_id,
                        guest_name:this.ruleForm_host.guest_name
                    }
                    let res = await this.$store.dispatch('baseConsole/createLive', params)
                    this.dialog_setGuest=false
                    this.getList()


                } else {
                    return false;
                }
            });
        },
        //设置嘉宾--打开
        setJiabing(row){
            this.data_item=row
            this.jiabinForm={
                avatar:row.avatar?row.avatar:this.DEFAULT_AVATAR,
                nickname:row.nickname,
                password:row.passwd,
            }
            this.dialog_jiabin=true
        },
        // 设置嘉宾--提交
        async sure_jiabin(formName){
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let params=this.jiabinForm
                    params.account=this.data_item.account
                    params.channel_id=this.$route.query.channel_id
                    let res = await this.$store.dispatch("baseConsole/updateGuests",params);
                    if(res && res.success){
                        this.getList()
                    }else{
                        this.$message.warning(this.$t('systemBusy'))
                    }
                    this.dialog_jiabin=false
                    

                } else {
                    return false;
                }
            });

            
        },
        // 头像
        _uploadCompleteOSS(res){
            this.jiabinForm.avatar = res.url;
        },
        //复制
        handleCopy(row,type) {
            const input = document.createElement("textarea");
            document.body.appendChild(input);
            
            if(type==='zhuchiren'){
                
                input.value = `${ row.nickname?row.nickname:this.$t('Moderator')}\n ${this.$t('Channelnumber')+'：'+row.account}\n${ this.$t('Channelpassword')+'：'+row.passwd}\n${this.$t('loginlink')+'：'+row.watch_url}`;
                
            }else{
                if(this.LOCALE=='zh'){
                    input.value = `${ !row.nickname||row.nickname==""?"嘉宾":row.nickname}，您好，点击该登录链接，输入您喜爱的昵称及将登录密码输入至第二个输入框，即可成功登录\n登录密码为：${row.passwd}\n您的登录链接为：${row.watch_url}`;
                }else{
                     input.value = `Hello ${ row.nickname?row.nickname:"Guest"}click this log in link, enter your favorite nickname and enter the login password into the second input box to log in successfully\nLog in password:：${row.passwd}\nlog in link：${row.watch_url}`;
                }
            }

            input.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy");
            }
            document.body.removeChild(input);
            this.$message.success(this.$t('copySuccess'));
        },

    }
}
</script>
<style scoped lang="less">
    .fff{
        background: #fff;
    }
    .line{
        width: 100%;
        height: 1px;
        background: #C0C4CC;
    }
    .lineBlock{
        height: 10px;
        background: #dddee0;
    }
    // .p_title{
    //     display: flex;
    //     justify-content: space-between;
    //     padding-right: 20px;
    // }
    .dialogBox{
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            width:80px;
        }
    }
    .tit{
        display: flex;
        align-items: center;
        img{
            width: 37px;
            height: 37px;
            border-radius:50%;
        }
        div{
            padding-left:8px;
            height: 20px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 800;
            color: rgba(0, 0, 0, 0.85);
        }
    }
    .states_box>div{
        display: flex ;
        align-items: center;
        div{
            width:6px;height:6px;
            background:#BFBFBF;
            border-radius:3px;
            display: inline-block;
            margin-right: 8px;
        }
        span{
            height: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #415058;
            line-height: 20px;
        }
    }
    .avaBox>div{
        display: flex;
        align-items: center;
        img{
            margin-right:10px;
        }
    }
</style>